import { render, staticRenderFns } from "./SdsTableSection9FlashPoint.vue?vue&type=template&id=62c1285a&scoped=true"
import script from "./SdsTableSection9FlashPoint.vue?vue&type=script&setup=true&lang=ts"
export * from "./SdsTableSection9FlashPoint.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SdsTableSection9FlashPoint.vue?vue&type=style&index=0&id=62c1285a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c1285a",
  null
  
)

export default component.exports